<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #ffffff; overflow: hidden; height: 100vh" width="200px">
				<a-menu v-for="item in menus" mode='inline' :selectedKeys="[selectedKeys]" >
					<a-menu-item :key="item.key" @click="goToUrl(item)">
						<span class="ft14 ml10">{{ item.name }}</span>
					</a-menu-item>
				</a-menu>
			</a-layout-sider>
			<a-layout-content
				style="padding:20px; height: 100vh; background: #f7f8fa;position: relative;">
				<div style="width: 100%;height: calc(100% - 55px);overflow-y: auto;">
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				menus:[
					{
						name: "群号管理",
						key:"group",
						link: "/othersLayout",
					},
					{
						name: "附近课程",
						key:"class",
						link: "/nearbyCourses",
					}
				],
				selectedKeys:'group'
			}
		},
		mounted() {
		  if (this.selectedKeys === 'group') {
		    this.$router.push(this.menus.find(menu => menu.key === 'group').link);
		  }
		},
		methods:{
			goToUrl(val){
				this.selectedKeys = val.key
				this.$router.push({
					path: val.link
				});
			}
		}
	}
</script>

<style>
</style>